<template>
  <div class="result">
    <van-nav-bar title="叫车成功，请等待"/>
    <div class="context main">
      <img :src="require('@/assets/img/call_taxi_success.png')" style="width: 130px; height: 109px;"/>
      <div class="call-success-text"> 叫车成功！</div>
      <div class="call-success-text2"> 请保持电话畅通</div>
      <p class="call-success-text3">
        您的叫车请求已发送给周围的出租车司机
        司机将通过您预留的手机号联系您
      </p>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import {NavBar} from 'vant';

  Vue.use(NavBar);
  export default {
    name: "success"
  }
</script>

<style scoped lang="scss">
  .result {
    width: 100%;
    background-color: #fff;
    font-size: 12px;
    align-items: center;
    box-sizing: border-box;

    .context {
      padding: 20px 40px;
      align-items: center;
    }
  }
</style>
